<template>
  <div>
    <section id="content">
      <div class="section nobg nomargin">
        <div class="container">
          <h3>
            Our Commitment to Transparency, Fraud Prevention, and Excellence
          </h3>

          <h4>Fundraiser & Contributor Bill of Rights</h4>
          <p>
            M-Changa believes that all customers - either a fundraiser
            originator or contributor - deserves the right to a fair, equitable,
            and reliable fundraising platform. To ensure this, we believe a
            fundraiser has obligations, a donor has an obligation, and M-Changa
            has an obligation.
          </p>

          <h5>M-Changa's Commitment</h5>
          <p>
            “At M-Changa, we are committed to using technology and data driven
            intelligence to help Kenyans succeed in creating, communicating, and
            mobilizing successful fundraisers that are authentic and effective.”
          </p>
          <p>
            We use a 30-point due diligence system for fraud prevention and risk
            management, customer service, and maximum user experience. This
            means that our Customer Service and Fraud Prevention team reviews
            each transaction, campaign story, and feedback from contributors and
            social networks.
          </p>
          How do we do this?
          <ul>
            <li>
              Human intelligence: We interview each donor and contributor to
              authenticate fundraisers.
            </li>
            <li>
              Technology: Our technology systems automatically detect any fraud
              or abuse of our platform
            </li>
            <li>
              Community: Our hotline is open for any cross-checking of campaign
              authenticity
            </li>
          </ul>

          <h5>Fundraiser Expectations and Obligations</h5>
          <p>
            <strong>Transparency:</strong> Create a fundraiser using our
            fundraiser tips - use powerful stories, share pictures and videos,
            and talk about the fundraiser goals and achievements. But be
            transparent with your organization - if you're asking your friends
            and family to support you, make sure you're telling the truth and
            not a story you think they want to hear.
          </p>
          <p>
            <strong>Communicate: </strong>Tell your stroty with SMS, word of
            mouth, and social media. Be reachable to your donors through phone,
            SMS, email, and social media. Answer questions from your donors.
            Update your story and show your donors that you are reliable. Use
            the technology: The power of M-Changa is the ability to capitalize
            on the viral effect. Mobilize your social network, and even people
            you may not know! Our product allows you to tell your story through
            social media, mapping technology, and message boards. Show
            accountability by creating a credible fundraiser that has oversight
            by a committee.
          </p>

          <h5>Donor Obligation</h5>
          <p>
            <strong>Learn about the fundraiser: </strong> Read about the
            campaign story, use the internet and social media to confirm the
            authenticity, and don't fall into any traps of promotions or alarms
            about the fundraiser.
          </p>
          <p>
            <strong>Tell us: </strong> Communicate with us if there are any
            concerns about the fundraiser. If there is a fundraiser for a
            wedding, and you know the wedding happened six months ago, tell us
            and we will use our 30 point due diligence system to suspend the
            campaign. We take this seriously.
          </p>
          <p>
            <strong>Understand the risks: </strong>Know that contributing to a
            fundraiser can be rewarding, but there is a risk to supporting a
            non-transparent and fraudulent fundraiser. All contributions are
            voluntary. We will do everything that we commit to doing for
            creating transparent and reliable fundraisers, but it can be a risk.
            Learn about your Rights and Responsibilities here.
          </p>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>
import Breadcrumbs from "../components/Breadcrumbs.vue";
import Footer from "../components/Footer/index.vue";
export default {
  components: {
    Breadcrumbs,
    Footer,
  },
  data() {
    return {};
  },
};
</script>
